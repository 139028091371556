import React, { useState, useEffect } from "react";
import { rate, Rating, quality, winProbability } from "ts-trueskill";
import useRatings from "../hooks/useRatings";

const PlayersDropdown = ({
  playersArray,
  data,
  team,
  setTeams,
  setQuality,
  setProbability,
  index,
}) => {
  const playerIndex = player =>
    data.findIndex(element => element.name === player);
  const updatePlayers = p => {
    let newArray = [...team];
    if (p === "New Player") {
      newArray[index] = new Rating();
    } else {
      newArray[index] = data[playerIndex(p)].rating;
    }

    setTeams(newArray);
  };

  return (
    <select onChange={e => updatePlayers(e.target.value)}>
      <option>New Player</option>
      {playersArray &&
        playersArray.map(player => {
          return (
            <option key={player} value={player}>
              {player}
            </option>
          );
        })}
    </select>
  );
};

export default function Quality({ data }) {
  const [ratings, createRatings] = useRatings();
  const [blue, setBlue] = useState([
    new Rating(),
    new Rating(),
    new Rating(),
    new Rating(),
    new Rating(),
  ]);
  const [red, setRed] = useState([
    new Rating(),
    new Rating(),
    new Rating(),
    new Rating(),
    new Rating(),
  ]);
  const [matchQuality, setQuality] = useState(quality([blue, red]));
  const [probability, setProbability] = useState(winProbability(blue, red));
  const [playersArray, setPlayersArray] = useState();

  useEffect(() => {
    if (ratings.length === 0) {
      createRatings(data);
    }
  }, []);

  useEffect(() => {
    const names = ratings.map(entry => {
      return entry.name;
    });
    setPlayersArray(names.sort());
  }, [ratings.length]);

  useEffect(() => {
    setQuality(quality([blue, red]));
    setProbability(winProbability(blue, red));
  }, [blue, red]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
      }}
    >
      <div>Quality: {matchQuality.toFixed(2)}</div>
      <div />
      <div>Win Probability: {(100 * probability).toFixed(2)}%</div>
      <div>Win Probability: {(100 * (1 - probability)).toFixed(2)}%</div>
      <div style={{ display: "grid", gridAutoFlow: "row", gap: "10px" }}>
        {blue.map((p, i) => {
          return (
            <PlayersDropdown
              key={i}
              playersArray={playersArray}
              data={ratings}
              team={blue}
              setTeams={setBlue}
              index={i}
            />
          );
        })}
      </div>
      <div style={{ display: "grid", gridAutoFlow: "row", gap: "10px" }}>
        {red.map((p, i) => {
          return (
            <PlayersDropdown
              key={i}
              playersArray={playersArray}
              data={ratings}
              team={red}
              setTeams={setRed}
              index={i}
            />
          );
        })}
      </div>
    </div>
  );
}
