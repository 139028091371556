import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Quality from "../components/Quality";

const QualityPage = ({ data }) => (
  <>
    <SEO title="Quality" />
    <Quality data={data} />
  </>
);

export const query = graphql`
  query {
    allDataSheetsData {
      edges {
        node {
          id
          blue1
          blue2
          blue3
          blue4
          blue5
          red1
          red2
          red3
          red4
          red5
          winner
          date
        }
      }
    }
  }
`;

export default QualityPage;
